import UserManagementBaseCard from '@components/UserManagement/UserManagementBaseCard'
import TextField from '@components/Form/TextField'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBuilding, faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import Button from '@components/Form/Button'
import BuildingSelector from '@components/UserManagement/BuildingSelector'
import { useStoreState } from 'easy-peasy'
import { useState, useEffect, useMemo } from 'react'
import { apiErrorHandler } from '../../Utilities/Error'
import { getSites } from '../../API/Services/Point'
import { getProgramBuildingsAdmin } from '../../API/Services/Flex'
import ToggleSwitch from '@components/ToggleSwitch'

const FlexAppConfigCard = (props) => {
  // destructure props
  const { pendingForm, setPendingForm, initial, enableFlexConfig, setEnableFlexConfig } = props

  // GLOBAL STATE
  const loggedInUser = useStoreState((state) => state.auth.user)
  const currentPartner = useStoreState((state) => state.partner.selected)

  // LOCAL STATE
  const [loggedInUserSiteOptions, setLoggedInUserSiteOptions] = useState([]) // sites logged-in user has access to
  const [buildingsInFlexProgram, setBuildingsInFlexProgram] = useState([]) // buildings in the flex program
  const [pendingFormSiteIds, setPendingFormSiteIds] = useState([]) // all sites the user has a building selector for
  const [remainingSites, setRemainingSites] = useState(loggedInUserSiteOptions) // how many sites the logged-in user has access to but no building selector is present for
  const [buildingSelectorCount, setBuildingSelectorCount] = useState(
    pendingFormSiteIds?.length ? pendingFormSiteIds?.length : 1
  ) // how many building selectors should be present

  // get all sites and buildings in the flex program on page load
  useMemo(async () => {
    const programBuildings = await getProgramBuildingsAdmin()
    setBuildingsInFlexProgram(programBuildings?.data ?? [])
  }, [])

  // store initial site ids in pending form - both explicit and implicit
  // this prevents an issue on initial selector load where deselecting all buildings removes the selector
  useMemo(() => {
    // get all site ids in the initial form - both explicitly assigned and implicitly assigned via buildings
    const implicitSiteIds = buildingsInFlexProgram
      .filter((x) => initial?.access?.buildingIds.includes(x.id))
      .map((b) => b.siteId)
    const allInitialSiteIds = [...new Set(implicitSiteIds.concat(initial?.access?.siteIds ?? []))]

    props.setPendingForm({
      ...pendingForm,
      access: {
        siteIds: [...new Set(allInitialSiteIds.concat(pendingForm?.access?.siteIds ?? []))],
        buildingIds: pendingForm?.access?.buildingIds,
      },
    })
  }, [initial?.access?.siteIds, initial?.access?.buildingIds, buildingsInFlexProgram])

  // get available sites based on logged-in user's access
  useEffect(() => {
    const getSiteOptions = async () => {
      try {
        // get site ids in flex progam
        const sitesInFlexProgram = buildingsInFlexProgram.map((b) => b.siteId)

        // get full site data for flex sites - filtered by logged-in user access
        const userSiteData = await getSites(currentPartner.value, sitesInFlexProgram)

        setLoggedInUserSiteOptions(userSiteData.data) // full site data, not just ids
      } catch (e) {
        apiErrorHandler(e)
      }
    }
    if (loggedInUser && buildingsInFlexProgram.length) {
      getSiteOptions()
    } else {
      setLoggedInUserSiteOptions([])
    }
  }, [loggedInUser, currentPartner, buildingsInFlexProgram])

  // handle a change in pending form site ids or logged-in user site options (i.e. partner change)
  useEffect(() => {
    // get all site ids in the pending form - both explicitly assigned and implicitly assigned via buildings
    const implicitSiteIds = buildingsInFlexProgram
      .filter((x) => pendingForm?.access?.buildingIds.includes(x.id))
      .map((b) => b.siteId)
    const allPendingSiteIds = [...new Set(implicitSiteIds.concat(pendingForm?.access?.siteIds ?? []))]

    // filter out sites logged-in user doesn't currently have access to
    const userAccessiblePendingSiteIds = loggedInUserSiteOptions
      .filter((site) => allPendingSiteIds.includes(site.ID))
      .map((s) => s.ID)

    // set site ids to display selector for
    setPendingFormSiteIds(userAccessiblePendingSiteIds)
    // filter out sites already selected
    setRemainingSites(loggedInUserSiteOptions.filter((site) => !userAccessiblePendingSiteIds.includes(site.ID)))
    // set building selector count based on pending form site ids
    setBuildingSelectorCount(userAccessiblePendingSiteIds.length ? userAccessiblePendingSiteIds.length : 1)
  }, [pendingForm?.access?.siteIds, pendingForm?.access?.buildingIds, loggedInUserSiteOptions])

  // add building selector objects for each site in the pending form - minimum of one if no sites in pending form
  // make at least one site config required
  const buildingSelectors = []
  for (let i = 0; i < buildingSelectorCount; i++) {
    const site = pendingFormSiteIds ? loggedInUserSiteOptions.filter((s) => s.ID === pendingFormSiteIds[i]) : []
    const buildings = buildingsInFlexProgram.filter((b) => b.siteId === site[0]?.ID)
    const initialBuildingSelections = initial?.access?.buildingIds?.filter((id) =>
      buildings.map((b) => b.id).includes(id)
    )

    buildingSelectors.push(
      <div className="row">
        <div className="form-field">
          <BuildingSelector
            required={i === 0}
            key={`building-selector-${i}`}
            pendingForm={pendingForm}
            setPendingForm={setPendingForm}
            pendingFormSiteIds={pendingFormSiteIds}
            siteOptions={remainingSites}
            site={site}
            buildingsInFlexProgram={buildings}
            initialBuildingSelections={initialBuildingSelections}
          />
        </div>
      </div>
    )
  }

  // construct card-specific form rows
  const formRows = (
    <div className="form">
      <ToggleSwitch initialState={enableFlexConfig} onChange={setEnableFlexConfig}>
        Enable Flex Access:
      </ToggleSwitch>
      {enableFlexConfig && (
        <>
          <div className="row" style={{ width: '50%' }}>
            <TextField
              name="role"
              label="Job Title"
              initial={initial && initial?.role}
              value={pendingForm?.role}
              onChange={props.onChange}
            />
          </div>
          <div className="row">
            <div className="form-field">
              <div className="title">
                <span>Building Access</span>
              </div>
            </div>
          </div>
          {buildingSelectors}
          {remainingSites.length > 0 &&
            buildingSelectors.length < loggedInUserSiteOptions.length &&
            pendingFormSiteIds.length === buildingSelectors.length && (
              <div className="row">
                <div className="action">
                  <Button
                    customClassNames="no-fill"
                    label="Add Buildings"
                    icon={
                      <FontAwesomeIcon
                        class="icon"
                        icon={faPlusCircle}
                        style={{ color: '#3D2F90', float: 'left', cursor: 'pointer', paddingRight: '8px' }}
                      />
                    }
                    onClick={() => setBuildingSelectorCount(buildingSelectorCount + 1)}
                    style={{ color: '#3D2F90' }}
                  />
                </div>
              </div>
            )}
        </>
      )}
    </div>
  )

  return <UserManagementBaseCard icon={faBuilding} groupName={'Flex App'} formRows={formRows} />
}

export default FlexAppConfigCard
