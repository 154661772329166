import * as validator from 'validator'
import qs from 'qs'

import { flexClient } from '../FlexApi'

/**
 * @typedef {object} FlexNotifications
 * @property {boolean} email Toggle to receive email notifications
 * @property {boolean} phone Toggle to receive phone notifications
 */

/**
 * @typedef {object} FlexAccess
 * @property {number[]} siteIds Sites the user has access to
 * @property {number[]} buildingIds Buildings the user has access to
 * @property {number} buildingCount Count of buildings the user has access to
 */

/**
 * @typedef {object} FlexUser
 * @property {number} id User primary identifier
 * @property {string} name User's first and last name
 * @property {string} email User's email address
 * @property {string} role User's job description
 * @property {string} phone User's phone number, with country and area code (e.g. +11234567890)
 * @property {FlexNotifications} notifications What notification types the user is configured to receive
 * @property {FlexAccess} access User's configured site and building access
 */

/**
 * Get users from Flex API using email filtering
 * @param {string[]} emails
 * @returns {Promise<{
 *  id: number,
 *  name: string,
 *  email: string,
 *  role: string,
 *  phone: string,
 *  notifications: {
 *    email: boolean,
 *    phone: boolean,
 *  },
 *  access: {
 *    siteIds: number[],
 *    buildingIds: number[],
 *    buildingCount: number
 *  }
 * }[]>}
 */
export const getUsers = async (emails) => {
  // Flex getUsers rejects malformed emails.
  // We need to filter out invalid emails before sending the get requests.
  const validEmails = emails.filter((email) => validator.isEmail(email))

  // batch requests to avoid url query string limitations
  const requests = []
  for (let i = 0; i < validEmails.length; i += 25) {
    const config = { params: { email: validEmails.slice(i, i + 25), batch: i } }
    requests.push(flexClient.get('/user', config))
  }

  const responses = await Promise.all(requests)

  return responses.reduce((agg, response) => {
    agg.push(...response.data)
    return agg
  }, [])
}

/**
 * Create a user in Flex API, using admin route to waive requesting user site/building access verification
 * @param {*} user
 * @returns
 */
export const postUserAdmin = async (user) => {
  return flexClient.post('/admin/user', user)
}

/**
 * Get all buildings in the flex program, using admin route to waive requesting user site/building access verification
 * @returns {Promise<{
 *  id: number,
 *  name: string,
 *  address: {
 *    street: string,
 *    city: string,
 *    state: string,
 *    zip: string,
 *  }
 *  siteId: number,
 *  timezone: string,
 * }[]>}
 */
export const getProgramBuildingsAdmin = async () => {
  return flexClient.get('/admin/program/building')
}

/**
 * Update a user in Flex API, using admin route to waive requesting user site/building access verification
 * @param {number} userId
 * @param {*} user
 * @returns {Promise<FlexUser>}
 */
export const patchUserAdmin = async (userId, user) => {
  return flexClient.patch(`/admin/user/${userId}`, user)
}

/**
 * Delete a user in Flex API, using admin route
 * @param {number} userId
 * @returns
 */
export const deleteUserAdmin = async (userId) => {
  return flexClient.delete(`/admin/user/${userId}`)
}

/**
 * Get a building-program map
 * @param {number[]=} buildingIds
 * @returns {Promise<{data: Object}>}
 */
export const getBuildingProgramMap = (buildingIds) => {
  const config = {
    params: {
      buildingId: buildingIds,
    },
    paramsSerializer: (params) => {
      return qs.stringify(params, { arrayFormat: 'repeat' })
    },
  }

  return flexClient.get('/building/program-map', config)
}

/**
 * Get event details from flex api
 * @param {number} eventId
 * @returns {Promise<import('axios').AxiosResponse>}
 */
export const getEventShifts = (eventIds, programIds) => {
  const config = {
    params: {
      eventId: eventIds,
      programId: programIds,
    },
    paramsSerializer: (params) => {
      return qs.stringify(params, { arrayFormat: 'repeat' })
    },
  }

  return flexClient.get(`/admin/event/shift`, config)
}

/**
 * PUT event shift data for an event
 * @param {number} eventId
 * @param {number} programId
 * @param {number} units
 * @param {number} compensation
 * @returns {Promise<import('axios').AxiosResponse>}
 */
export const putEventShift = (eventId, programId, units, compensation) => {
  const payload = {
    units,
    compensation,
  }

  return flexClient.put(`/admin/event/${eventId}/program/${programId}/shift`, payload)
}
